import React from "react";
import CommonAbout from "../common/CommonAbout";
import ourProfile from "../../assets/images/webp/our-profile.webp";
import ourVision from "../../assets/images/webp/our-vision.webp";
import ourMission from "../../assets/images/webp/our-mission.webp";
import CommonAboutSecond from "../common/CommonAboutSecond";

const AboutDetails = () => {
  return (
    <div id="aboutHero">
      <CommonAbout
        image={ourProfile}
        imageAlt="ourProfile"
        imageClass="max-lg:!object-top"
        orangeHeadingSecond="hidden"
        orangeTextClass="mb-3"
        blackHeadingClass="max-sm:mb-3 mb-4"
        orangeText="About Us"
        blackHeadingText="Our Profile"
        paraText="At Agarwal Shifting, we understand that moving can be a stressful experience. That's why we're here to make it smooth, efficient, and hassle-free for you. As a leading packers and movers company in South Goa, we take pride in offering top-notch relocation services tailored to meet your specific needs.We have vast experience in the field of relocation and transportation industry; thus providing right solutions for all kinds of packing and moving needs in South Goa.
We understand that every move is unique, which is why we offer customizable moving solutions to fit your schedule, budget, and requirements. Our professional packers use high-quality materials and techniques to ensure that your items are well-protected during transit, and our storage facilities provide a safe and secure option for short-term or long-term storage needs."
        paraClass="max-w-[557px] max-md:max-w-[600px] max-lg:max-w-[750px] max-lg:text-center max-sm:max-w-[450px]"
        btnClass="!hidden"
      />
      <CommonAboutSecond
        image={ourMission}
        imageAlt="Our Mission"
        imageClass="max-lg:!object-top"
        orangeHeadingSecond="hidden"
        orangeTextClass="mb-3"
        blackHeadingClass="max-sm:mb-3 mb-4"
        orangeText="About Us"
        blackHeadingText="Our Mission"
        paraText="At Agarwal Shifting, our mission is simple: to provide our customers with exceptional moving services that exceed their expectations at every step of the journey. We are committed to making the moving process as stress-free and seamless as possible, ensuring that our clients can focus on settling into their new homes or offices with peace of mind."
        paraClass="max-w-[557px] max-md:max-w-[600px] max-lg:max-w-[750px] max-lg:text-center max-sm:max-w-[450px]"
        btnClass="!hidden"
      />
      <CommonAbout
        image={ourVision}
        imageAlt="ourVision"
        imageClass="max-lg:!object-center"
        orangeHeadingSecond="hidden"
        orangeTextClass="mb-3"
        blackHeadingClass="max-sm:mb-3 mb-4"
        orangeText="About Us"
        blackHeadingText="Our Vision"
        paraText="At Agarwal Shifting, our vision is to redefine the moving experience by setting new standards of excellence and innovation in the industry. We envision a future where every move is seamless, stress-free, and tailored to the unique needs of each customer."
        paraClass="max-w-[557px] max-md:max-w-[600px] max-lg:max-w-[750px] max-lg:text-center max-sm:max-w-[450px]"
        btnClass="!hidden"
      />
    </div>
  );
};

export default AboutDetails;
