import React from "react";
import Hero from "../common/Hero";
import Service from "../servicepage/Service";

const Services = () => {
  return (
    <>
      <Hero
        orangeText="Agarwal Shifting, South Goa"
        headingText="All Services"
        headingClass="max-w-[700px] max-lg:max-w-[600px] max-md:max-w-[450px] max-sm:max-w-[320px]"
        paragraph="From household and office relocations to vehicle shifting, Packers and Movers in South Goa provide seamless, reliable, and on-time services. Let us handle your move with care and professionalism."
        btnClass="!hidden"
        background="bg-services"
        paraClass="max-w-[486px] max-sm:max-w-[320px] max-md:max-w-[390px]"
        spacing="xl:!pt-[180px]"
      />
      <Service />
    </>
  );
};

export default Services;
