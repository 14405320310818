import Hero from "../common/Hero";
import CommonAbout from "../common/CommonAbout";
import homeAbout from "../../assets/images/webp/home-about.webp";
import HomepageServices from "../homepage/HomepageServices";
import HomepageProcess from "../homepage/HomepageProcess";
import HomepageCharges from "../homepage/HomepageCharges";
import Quote from "../homepage/Quote";

const Home = () => {
  return (
    <>
    <Hero
        headingText="Car Relocation Service in South Goa"
        headingClass="max-w-[583px] max-sm:max-w-[320px] max-md:max-w-[350px] max-lg:max-w-[500px]"
        orangeText="Agarwal Shifting, South Goa"
        background="bg-hero" 
        paragraph="Find car Relocation service in South Goa. Trusted and affordable car carrier services in South Goa. We use Containers to move your vehicles to the ideal objective to stay away from scratches on vehicles while on the way from [Company City] to All across India."
        paraClass="md:max-w-[583px] max-sm:max-w-[400px] max-md:max-w-[450px]"
      />
      <Quote />
      <CommonAbout
        image={homeAbout}
        imageAlt="worker"
        blackHeadingClass="mb-2 max-lg:max-w-[500px] mx-auto max-lg:!text-center max-md:max-w-[400px] max-sm:max-w-[320px]"
        blackHeadingText="Best Packers and Movers South Goa"
        orangeText="About Us"
        orangeTextClass="mb-3"
        paraText="Welcome to Agarwal Shifting, the premier packers and movers in South Goa. With a rich history of providing top-tier moving services, we have become a trusted name in the industry. Our journey began with a simple yet powerful mission: to redefine the moving experience by offering unparalleled service, quality, and reliability. At Agarwal Shifting, we understand that moving can be a daunting task. Whether it's relocating your home, office, or even just a single item, the process involves careful planning, meticulous packing, and safe transportation. Our team of experienced professionals is dedicated to making this transition as smooth and stress-free as possible for you."
        paraClass="max-w-[557px] max-sm pt-4 max-lg:text-center max-sm:max-w-[450px]"
      />
      <HomepageProcess />
      <HomepageCharges />
      <HomepageServices />
    </>
  );
};

export default Home;
