export const OPTION_ICON = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.50394 12.5036C5.42193 12.5849 5.35683 12.6817 5.31241 12.7883C5.26799 12.8949 5.24512 13.0093 5.24512 13.1248C5.24512 13.2403 5.26799 13.3547 5.31241 13.4613C5.35683 13.5679 5.42193 13.6647 5.50394 13.7461L9.87894 18.1211C9.96028 18.2031 10.0571 18.2682 10.1637 18.3126C10.2703 18.357 10.3847 18.3799 10.5002 18.3799C10.6157 18.3799 10.7301 18.357 10.8367 18.3126C10.9433 18.2682 11.0401 18.2031 11.1214 18.1211L15.4964 13.7461C15.5785 13.6647 15.6435 13.5679 15.688 13.4613C15.7324 13.3547 15.7553 13.2403 15.7553 13.1248C15.7553 13.0093 15.7324 12.8949 15.688 12.7883C15.6435 12.6817 15.5785 12.5849 15.4964 12.5036C15.4151 12.4215 15.3183 12.3565 15.2117 12.312C15.1051 12.2676 14.9907 12.2447 14.8752 12.2447C14.7597 12.2447 14.6453 12.2676 14.5387 12.312C14.4321 12.3565 14.3353 12.4215 14.2539 12.5036L10.5002 16.2661L6.74644 12.5036C6.6651 12.4215 6.56832 12.3565 6.4617 12.312C6.35507 12.2676 6.2407 12.2447 6.12519 12.2447C6.00968 12.2447 5.89531 12.2676 5.78869 12.312C5.68206 12.3565 5.58528 12.4215 5.50394 12.5036ZM9.87894 2.87856L5.50394 7.25356C5.42236 7.33514 5.35764 7.432 5.31349 7.53859C5.26934 7.64519 5.24661 7.75943 5.24661 7.87481C5.24661 8.10782 5.33917 8.33129 5.50394 8.49606C5.58552 8.57764 5.68238 8.64236 5.78897 8.68651C5.89557 8.73066 6.00981 8.75339 6.12519 8.75339C6.3582 8.75339 6.58168 8.66082 6.74644 8.49606L10.5002 4.73356L14.2539 8.49606C14.3353 8.57807 14.4321 8.64317 14.5387 8.68759C14.6453 8.73201 14.7597 8.75488 14.8752 8.75488C14.9907 8.75488 15.1051 8.73201 15.2117 8.68759C15.3183 8.64317 15.4151 8.57807 15.4964 8.49606C15.5785 8.41472 15.6435 8.31794 15.688 8.21131C15.7324 8.10469 15.7553 7.99032 15.7553 7.87481C15.7553 7.7593 15.7324 7.64493 15.688 7.5383C15.6435 7.43168 15.5785 7.3349 15.4964 7.25356L11.1214 2.87856C11.0401 2.79655 10.9433 2.73145 10.8367 2.68703C10.7301 2.64261 10.6157 2.61974 10.5002 2.61974C10.3847 2.61974 10.2703 2.64261 10.1637 2.68703C10.0571 2.73145 9.96028 2.79655 9.87894 2.87856Z"
      fill="black"
    />
  </svg>
);
export const INFO_PHONE_ICON = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0002 10.999H22.0002C22.0002 5.869 18.1272 2 12.9902 2V4C17.0522 4 20.0002 6.943 20.0002 10.999Z"
      fill="white"
    />
    <path
      d="M13.0003 8.00024C15.1033 8.00024 16.0003 8.89724 16.0003 11.0002H18.0003C18.0003 7.77524 16.2253 6.00024 13.0003 6.00024V8.00024ZM16.4223 13.4432C16.2301 13.2686 15.9776 13.1754 15.7181 13.1835C15.4585 13.1915 15.2123 13.3001 15.0313 13.4862L12.6383 15.9472C12.0623 15.8372 10.9043 15.4762 9.71228 14.2872C8.52028 13.0942 8.15928 11.9332 8.05228 11.3612L10.5113 8.96724C10.6977 8.78637 10.8064 8.54006 10.8144 8.28045C10.8225 8.02083 10.7292 7.76828 10.5543 7.57624L6.85928 3.51324C6.68432 3.3206 6.44116 3.20374 6.18143 3.1875C5.92171 3.17125 5.66588 3.2569 5.46828 3.42624L3.29828 5.28724C3.12539 5.46075 3.0222 5.69169 3.00828 5.93624C2.99328 6.18624 2.70728 12.1082 7.29928 16.7022C11.3053 20.7072 16.3233 21.0002 17.7053 21.0002C17.9073 21.0002 18.0313 20.9942 18.0643 20.9922C18.3088 20.9786 18.5396 20.8749 18.7123 20.7012L20.5723 18.5302C20.7417 18.3328 20.8276 18.077 20.8115 17.8173C20.7954 17.5576 20.6788 17.3143 20.4863 17.1392L16.4223 13.4432Z"
      fill="white"
    />
  </svg>
);
export const INFO_LOC_ICON = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12 12.75C11.4067 12.75 10.8266 12.5741 10.3333 12.2444C9.83995 11.9148 9.45543 11.4462 9.22837 10.8981C9.00131 10.3499 8.9419 9.74667 9.05765 9.16473C9.17341 8.58279 9.45913 8.04824 9.87869 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.1481 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.8241 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7954 12.749 12 12.75Z"
      fill="white"
    />
  </svg>
);
export const INFO_MAIL_ICON = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="white" />
  </svg>
);
export const NEXT_ARROW = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${className} duration-300 ease-linear`}
        d="M8 0L6.57 1.393L12.15 7H0V9H12.15L6.57 14.573L8 16L16 8L8 0Z"
        fill="#FD8447"
      />
    </svg>
  );
};
export const PREV_ARROW = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${className} duration-300 ease-linear`}
        d="M8 0L9.43 1.393L3.85 7H16V9H3.85L9.43 14.573L8 16L0 8L8 0Z"
        fill="#141F3D"
      />
    </svg>
  );
};
